import React from 'react';
import { CircleCheckmark } from '../shared';

import { useBreakpoints, Breakpoints, Directions } from 'src/utils/breakpoint-utils';

interface CheckmarkBulletItemProps {
    key: number;
    item: string;
}

const CheckmarkBulletItem = ({ key, item }: CheckmarkBulletItemProps) => {
    const isDesktop = useBreakpoints(Breakpoints.lg, Directions.up);
    return (
        <li key={key}>
            <CircleCheckmark size={isDesktop ? 34 : 16} />
            {item}
        </li>
    );
};

export default CheckmarkBulletItem;
