import React from 'react';
import { CarouselProvider, Slider, DotGroup } from 'pure-react-carousel';

import * as Styles from 'src/styles/partners-carousel.module.scss';

interface CarouselProps {
    totalSlides: number;
}

const Carousel: React.FC<CarouselProps> = ({ totalSlides, children }) => {
    return (
        <div className={Styles.partnersCarousel}>
            <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={0}
                totalSlides={totalSlides}
            >
                <Slider>{children}</Slider>
                <DotGroup />
            </CarouselProvider>
        </div>
    );
};

export default Carousel;
