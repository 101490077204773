// import { Selectors } from "@pvolve/sdk/src/app/modules/boot/actions";
import Selectors from 'src/state/root-selectors';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'src/state/root-actions';
import { isEmpty } from 'lodash';

export const usePartner = (slug: string) => {
    const dispatch = useDispatch();
    const loading = useSelector(Selectors.partner.loading);
    const partner = useSelector(Selectors.partner.current);

    useEffect(() => {
        if (!isEmpty(slug)) {
            dispatch(Actions.partner.show.trigger({ slug }));
        }
    }, [dispatch, slug]);

    useEffect(() => {
        if (isEmpty(slug) && partner) {
            dispatch(Actions.partner.show.clear(undefined));
        }
    }, [dispatch, slug, partner]);

    return { partner, loading };
};
