import React from 'react';
import Testimonial from './Testimonial';
import ReactMarkdown from 'react-markdown';

import { IContributingExpertFields } from '@pvolve/sdk/src/models/contentful_types';

import * as Styles from 'src/styles/partners-clinical-advisory.module.scss';

interface ClinicalAdvisoryProps {
    text?: string;
    header?: string;
    footerText?: string;
    experts?: IContributingExpertFields[];
}

const ClinicalAdvisory = ({ experts = [], header, text, footerText }: ClinicalAdvisoryProps) => {
    return (
        <>
            <div className={Styles.clinicalAdvisory}>
                <h2 className="h2 upper">{header}</h2>
                <p className="p1 margin-bottom--large">{text}</p>
                {experts.map((expert) => (
                    <Testimonial
                        key={expert?.id}
                        text={expert?.bio}
                        image={expert?.image}
                        headingOne={expert?.name}
                        headingTwo={expert?.title}
                        className="margin-bottom--large"
                    />
                ))}
            </div>

            <div className={Styles.footer}>
                <div className="p1">
                    {footerText && <ReactMarkdown>{footerText}</ReactMarkdown>}
                </div>
            </div>
        </>
    );
};

export default ClinicalAdvisory;
