import React from 'react';

import { Asset } from 'contentful';
import { Icon } from '../shared';
import CheckmarkBulletItem from './CheckmarkBulletItem';

import { useBreakpoints, Directions, Breakpoints } from 'src/utils/breakpoint-utils';

import * as Styles from 'src/styles/partners-header.module.scss';

interface HeroBgImage extends Asset {
    src?: string;
    alt?: string;
}

interface HeaderProps {
    heroHeading?: string;
    bulletPoints?: Array<string>;
    heroBgImage?: HeroBgImage;
}

const Header = ({ heroHeading, heroBgImage, bulletPoints }: HeaderProps) => {
    const isDesktop = useBreakpoints(Breakpoints.lg, Directions.up);

    const styles = {
        backgroundSize: 'cover',
        backgroundImage: `url(${heroBgImage?.src})`,
    };

    return (
        <div className={Styles.header} style={styles}>
            <h1 className="h1 bold upper" id="benefits">
                {heroHeading}
            </h1>

            {bulletPoints && bulletPoints.length > 0 && (
                <ul className={Styles.bulletList}>
                    {bulletPoints.map((item, i) => {
                        return <CheckmarkBulletItem key={i} item={item}></CheckmarkBulletItem>;
                    })}
                </ul>
            )}

            <a href={isDesktop ? '#education' : '#benefits'} className={`small ${Styles.seeMore}`}>
                see more <Icon name="pv-chevron-down" size={10} />
            </a>
        </div>
    );
};

export default Header;
