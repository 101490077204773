import React from 'react';
import { Asset } from 'contentful';

import * as Styles from 'src/styles/partners-education-carousel.module.scss';

interface Image extends Asset {
    src?: string;
    alt?: string;
}

interface EducationBlockProps {
    image?: Image;
    text?: string;
    heading?: string;
}

const EducationBlock = ({ heading, image, text }: EducationBlockProps) => {
    return (
        <div id="education" className={Styles.educationCarousel}>
            <h2 className="h2 upper">{heading}</h2>

            <div className={Styles.educationCarouselSlide}>
                <img src={image?.src} alt={image?.alt} className={Styles.img}></img>
                <p className="p1">{text}</p>
            </div>
        </div>
    );
};

export default EducationBlock;
