import React from 'react';
import { Asset } from 'contentful';

import * as Styles from 'src/styles/partners-testimonial.module.scss';

interface TestimonialProps {
    headingOne?: string;
    headingTwo?: String;
    text?: string;
    className?: string;
    image?: string;
}

const Testimonial: React.FC<TestimonialProps> = ({
    headingOne,
    headingTwo,
    text,
    image,
    className,
}) => {
    return (
        <div className={`${Styles.testimonial} ${className}`}>
            <img src={image} className={Styles.img}></img>
            <h4 className="h4 accent">
                {headingOne} <br /> {headingTwo}
            </h4>
            <p className="p1">"{text}"</p>
        </div>
    );
};

export default Testimonial;
