import React from 'react';

import Carousel from './Carousel';
import Testimonial from './Testimonial';

import { Slide } from 'pure-react-carousel';
import { ITestimonialFields } from '@pvolve/sdk/src/models/contentful_types';

import * as Styles from 'src/styles/partners-testimonials-carousel.module.scss';
interface TestimonialsProps {
    testimonialsHeading?: string;
    testimonials?: ITestimonialFields[];
}

const Testimonials = ({ testimonials = [], testimonialsHeading }: TestimonialsProps) => {
    return (
        <div className={Styles.testimonialsCarousel}>
            <h2 className="h2 upper">{testimonialsHeading} </h2>
            <Carousel totalSlides={testimonials.length}>
                {testimonials.map((testimonial, i) => (
                    <Slide index={i} key={`${testimonial.id}-${i}`}>
                        <Testimonial
                            text={testimonial.text}
                            image={testimonial.image}
                            headingOne={testimonial.name}
                            headingTwo={testimonial.title}
                        />
                    </Slide>
                ))}
            </Carousel>
        </div>
    );
};

export default Testimonials;
