import React, { useEffect } from 'react';

import { Loader } from 'src/components/shared';
import Layout from 'src/components/layout/Layout';
import Header from 'src/components/partner-landing/Header';
import Testimonials from 'src/components/partner-landing/Testimonials';
import ActivationForm from 'src/components/partner-landing/ActivationForm';
import EducationBlock from 'src/components/partner-landing/EducationCarousel';
import ClinicalAdvisory from 'src/components/partner-landing/ClinicalAdvisory';

import { useSelector } from 'react-redux';
import { useLocation } from '@reach/router';
import Selectors from 'src/state/root-selectors';
import { usePartner } from 'src/state/partner/hooks';
import { ENTITLEMENT_SOURCE } from '@pvolve/sdk/src/app/modules/auth/constants';

import * as Styles from 'src/styles/activation-form.module.scss';
import Colors from 'src/utils/colors';

import { linkToExternal } from 'src/utils/url-utils';
import { ZELDA_HOME_LINK } from 'src/constants/url-constants';

const OptumActivate = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const product = params.get('product') || 'renewactive';
    const sector = params.get('sector') || 'government';
    const partner = `${product}-${sector}`;

    const loggedIn = useSelector(Selectors.auth.loggedIn);
    const userAttr = useSelector(Selectors.account.userAttributes);
    const currentPartner = userAttr?.object?.partner?.provider;

    const isActivatedOptum = loggedIn && currentPartner === ENTITLEMENT_SOURCE.optum;

    useEffect(() => {
        if (isActivatedOptum) {
            linkToExternal(ZELDA_HOME_LINK);
        }
    }, [isActivatedOptum]);

    const {
        partner: {
            hint,
            logo,
            experts,
            footerText,
            heroBgImage,
            heroHeading,
            bulletPoints,
            testimonials,
            sadPathMessage,
            instructionsLong,
            educationBlockText,
            educationBlockImage,
            testimonialsHeading,
            educationBlockHeading,
            contributingExpertText,
            contributingExpertHeader,
        },
    } = usePartner(partner);

    const pageContext = {
        theme: {
            layout: 'none',
        },
    };

    if (isActivatedOptum) {
        return (
            <div className={Styles.loaderWrapper}>
                <Loader spinnerColor={Colors.black} />
            </div>
        );
    }

    return (
        <Layout pageContext={pageContext}>
            <div className={Styles.pageWrapper}>
                <div className={Styles.scrollableContent}>
                    <Header
                        heroHeading={heroHeading}
                        heroBgImage={heroBgImage}
                        bulletPoints={
                            (bulletPoints && bulletPoints.substring(2).split('\n- ')) || undefined
                        }
                    />
                    <EducationBlock
                        text={educationBlockText}
                        image={educationBlockImage}
                        heading={educationBlockHeading}
                    />
                    <Testimonials
                        testimonials={testimonials}
                        testimonialsHeading={testimonialsHeading}
                    />
                    <ClinicalAdvisory
                        experts={experts}
                        footerText={footerText}
                        text={contributingExpertText}
                        header={contributingExpertHeader}
                    />
                </div>
                <div className={Styles.fixedContent}>
                    <ActivationForm
                        hint={hint}
                        partner={partner}
                        partnerLogo={logo}
                        sadPathMessageTxt={sadPathMessage}
                        instructionsLong={instructionsLong}
                    />
                </div>
            </div>
        </Layout>
    );
};

export default OptumActivate;
